.PrivacyStatement {

  ul.lst-kix_list_20-2 {
    list-style-type: none
  }

  ul.lst-kix_list_20-3 {
    list-style-type: none
  }

  .lst-kix_list_19-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_19-1 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_20-4 {
    list-style-type: none
  }

  ul.lst-kix_list_20-5 {
    list-style-type: none
  }

  .lst-kix_list_14-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_14-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_20-6 {
    list-style-type: none
  }

  ul.lst-kix_list_20-7 {
    list-style-type: none
  }

  ul.lst-kix_list_1-0 {
    list-style-type: none
  }

  ul.lst-kix_list_20-8 {
    list-style-type: none
  }

  .lst-kix_list_14-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_14-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_19-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_14-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_14-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_19-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_19-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_20-0 {
    list-style-type: none
  }

  ul.lst-kix_list_20-1 {
    list-style-type: none
  }

  .lst-kix_list_14-6 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_9-3 {
    list-style-type: none
  }

  ul.lst-kix_list_9-4 {
    list-style-type: none
  }

  ul.lst-kix_list_9-1 {
    list-style-type: none
  }

  ul.lst-kix_list_9-2 {
    list-style-type: none
  }

  ul.lst-kix_list_9-7 {
    list-style-type: none
  }

  ul.lst-kix_list_9-8 {
    list-style-type: none
  }

  ul.lst-kix_list_9-5 {
    list-style-type: none
  }

  ul.lst-kix_list_9-6 {
    list-style-type: none
  }

  ul.lst-kix_list_1-3 {
    list-style-type: none
  }

  ul.lst-kix_list_1-4 {
    list-style-type: none
  }

  ul.lst-kix_list_1-1 {
    list-style-type: none
  }

  ul.lst-kix_list_1-2 {
    list-style-type: none
  }

  ul.lst-kix_list_1-7 {
    list-style-type: none
  }

  ul.lst-kix_list_9-0 {
    list-style-type: none
  }

  ul.lst-kix_list_1-8 {
    list-style-type: none
  }

  ul.lst-kix_list_1-5 {
    list-style-type: none
  }

  .lst-kix_list_14-2 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_1-6 {
    list-style-type: none
  }

  ul.lst-kix_list_17-1 {
    list-style-type: none
  }

  ul.lst-kix_list_17-0 {
    list-style-type: none
  }

  ul.lst-kix_list_17-8 {
    list-style-type: none
  }

  .lst-kix_list_19-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_17-7 {
    list-style-type: none
  }

  ul.lst-kix_list_17-6 {
    list-style-type: none
  }

  ul.lst-kix_list_17-5 {
    list-style-type: none
  }

  ul.lst-kix_list_17-4 {
    list-style-type: none
  }

  ul.lst-kix_list_17-3 {
    list-style-type: none
  }

  .lst-kix_list_14-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_17-2 {
    list-style-type: none
  }

  .lst-kix_list_19-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_19-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_19-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_5-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_5-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_5-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_5-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_5-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_8-4 {
    list-style-type: none
  }

  ul.lst-kix_list_8-5 {
    list-style-type: none
  }

  .lst-kix_list_5-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_5-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_8-2 {
    list-style-type: none
  }

  ul.lst-kix_list_8-3 {
    list-style-type: none
  }

  ul.lst-kix_list_8-8 {
    list-style-type: none
  }

  ul.lst-kix_list_8-6 {
    list-style-type: none
  }

  ul.lst-kix_list_8-7 {
    list-style-type: none
  }

  .lst-kix_list_5-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_5-5 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_8-0 {
    list-style-type: none
  }

  ul.lst-kix_list_8-1 {
    list-style-type: none
  }

  .lst-kix_list_6-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_6-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_18-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_6-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_6-4 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_16-2 {
    list-style-type: none
  }

  ul.lst-kix_list_16-1 {
    list-style-type: none
  }

  .lst-kix_list_18-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_18-2 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_16-0 {
    list-style-type: none
  }

  .lst-kix_list_6-2 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_16-8 {
    list-style-type: none
  }

  ul.lst-kix_list_16-7 {
    list-style-type: none
  }

  ul.lst-kix_list_16-6 {
    list-style-type: none
  }

  ul.lst-kix_list_16-5 {
    list-style-type: none
  }

  ul.lst-kix_list_16-4 {
    list-style-type: none
  }

  .lst-kix_list_6-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_16-3 {
    list-style-type: none
  }

  .lst-kix_list_6-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_6-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_6-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_2-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_7-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_7-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_2-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_7-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_18-6 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_3-7 {
    list-style-type: none
  }

  ul.lst-kix_list_3-8 {
    list-style-type: none
  }

  .lst-kix_list_10-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_18-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_18-8 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_13-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_3-1 {
    list-style-type: none
  }

  ul.lst-kix_list_3-2 {
    list-style-type: none
  }

  .lst-kix_list_7-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_3-0 {
    list-style-type: none
  }

  ul.lst-kix_list_3-5 {
    list-style-type: none
  }

  ul.lst-kix_list_3-6 {
    list-style-type: none
  }

  ul.lst-kix_list_3-3 {
    list-style-type: none
  }

  ul.lst-kix_list_3-4 {
    list-style-type: none
  }

  .lst-kix_list_10-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_15-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_10-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_10-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_11-7 {
    list-style-type: none
  }

  ul.lst-kix_list_11-6 {
    list-style-type: none
  }

  .lst-kix_list_4-1 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_11-5 {
    list-style-type: none
  }

  ul.lst-kix_list_11-4 {
    list-style-type: none
  }

  ul.lst-kix_list_11-3 {
    list-style-type: none
  }

  .lst-kix_list_15-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_11-2 {
    list-style-type: none
  }

  ul.lst-kix_list_11-1 {
    list-style-type: none
  }

  ul.lst-kix_list_11-0 {
    list-style-type: none
  }

  .lst-kix_list_9-2 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_19-7 {
    list-style-type: none
  }

  ul.lst-kix_list_19-6 {
    list-style-type: none
  }

  .lst-kix_list_4-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_4-5 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_19-5 {
    list-style-type: none
  }

  ul.lst-kix_list_19-4 {
    list-style-type: none
  }

  ul.lst-kix_list_19-3 {
    list-style-type: none
  }

  ul.lst-kix_list_19-2 {
    list-style-type: none
  }

  ul.lst-kix_list_19-1 {
    list-style-type: none
  }

  .lst-kix_list_20-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_11-8 {
    list-style-type: none
  }

  ul.lst-kix_list_19-0 {
    list-style-type: none
  }

  .lst-kix_list_15-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_9-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_15-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_19-8 {
    list-style-type: none
  }

  ul.lst-kix_list_21-1 {
    list-style-type: none
  }

  ul.lst-kix_list_21-2 {
    list-style-type: none
  }

  ul.lst-kix_list_21-3 {
    list-style-type: none
  }

  ul.lst-kix_list_21-4 {
    list-style-type: none
  }

  .lst-kix_list_20-0 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_21-5 {
    list-style-type: none
  }

  ul.lst-kix_list_21-6 {
    list-style-type: none
  }

  ul.lst-kix_list_21-7 {
    list-style-type: none
  }

  ul.lst-kix_list_21-8 {
    list-style-type: none
  }

  .lst-kix_list_9-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_9-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_11-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_21-0 {
    list-style-type: none
  }

  .lst-kix_list_20-6 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_2-8 {
    list-style-type: none
  }

  .lst-kix_list_20-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_12-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_11-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_20-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_12-1 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_2-2 {
    list-style-type: none
  }

  ul.lst-kix_list_2-3 {
    list-style-type: none
  }

  ul.lst-kix_list_2-0 {
    list-style-type: none
  }

  ul.lst-kix_list_2-1 {
    list-style-type: none
  }

  .lst-kix_list_9-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_2-6 {
    list-style-type: none
  }

  .lst-kix_list_1-1 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_2-7 {
    list-style-type: none
  }

  .lst-kix_list_11-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_2-4 {
    list-style-type: none
  }

  ul.lst-kix_list_2-5 {
    list-style-type: none
  }

  ul.lst-kix_list_10-0 {
    list-style-type: none
  }

  .lst-kix_list_1-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_13-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_10-8 {
    list-style-type: none
  }

  ul.lst-kix_list_18-0 {
    list-style-type: none
  }

  ul.lst-kix_list_10-7 {
    list-style-type: none
  }

  .lst-kix_list_1-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_10-6 {
    list-style-type: none
  }

  ul.lst-kix_list_10-5 {
    list-style-type: none
  }

  ul.lst-kix_list_10-4 {
    list-style-type: none
  }

  ul.lst-kix_list_10-3 {
    list-style-type: none
  }

  .lst-kix_list_1-5 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_10-2 {
    list-style-type: none
  }

  ul.lst-kix_list_10-1 {
    list-style-type: none
  }

  .lst-kix_list_13-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_12-5 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_18-8 {
    list-style-type: none
  }

  ul.lst-kix_list_18-7 {
    list-style-type: none
  }

  ul.lst-kix_list_18-6 {
    list-style-type: none
  }

  ul.lst-kix_list_18-5 {
    list-style-type: none
  }

  .lst-kix_list_12-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_18-4 {
    list-style-type: none
  }

  .lst-kix_list_2-1 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_18-3 {
    list-style-type: none
  }

  ul.lst-kix_list_18-2 {
    list-style-type: none
  }

  ul.lst-kix_list_18-1 {
    list-style-type: none
  }

  .lst-kix_list_2-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_13-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_3-0 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_5-7 {
    list-style-type: none
  }

  ul.lst-kix_list_5-8 {
    list-style-type: none
  }

  .lst-kix_list_3-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_3-2 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_5-5 {
    list-style-type: none
  }

  ul.lst-kix_list_5-6 {
    list-style-type: none
  }

  .lst-kix_list_21-8 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_8-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_8-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_3-5 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_5-0 {
    list-style-type: none
  }

  .lst-kix_list_3-4 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_5-3 {
    list-style-type: none
  }

  .lst-kix_list_3-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_5-4 {
    list-style-type: none
  }

  ul.lst-kix_list_5-1 {
    list-style-type: none
  }

  .lst-kix_list_8-0 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_5-2 {
    list-style-type: none
  }

  .lst-kix_list_8-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_3-8 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_21-0 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_21-2 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_8-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_8-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_21-1 > li:before {
    content: "o  "
  }

  .lst-kix_list_8-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_13-5 {
    list-style-type: none
  }

  ul.lst-kix_list_13-4 {
    list-style-type: none
  }

  ul.lst-kix_list_13-3 {
    list-style-type: none
  }

  .lst-kix_list_3-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_21-6 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_13-2 {
    list-style-type: none
  }

  ul.lst-kix_list_13-1 {
    list-style-type: none
  }

  .lst-kix_list_3-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_13-0 {
    list-style-type: none
  }

  .lst-kix_list_8-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_21-7 > li:before {
    content: "o  "
  }

  .lst-kix_list_11-2 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_13-8 {
    list-style-type: none
  }

  .lst-kix_list_11-1 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_13-7 {
    list-style-type: none
  }

  .lst-kix_list_21-5 > li:before {
    content: "\0025aa  "
  }

  ul.lst-kix_list_13-6 {
    list-style-type: none
  }

  .lst-kix_list_21-4 > li:before {
    content: "o  "
  }

  .lst-kix_list_11-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_8-8 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_21-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_16-8 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_16-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_16-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_4-8 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_4-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_17-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_17-1 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_4-8 {
    list-style-type: none
  }

  .lst-kix_list_16-0 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_4-6 {
    list-style-type: none
  }

  .lst-kix_list_16-1 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_4-7 {
    list-style-type: none
  }

  .lst-kix_list_16-2 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_4-0 {
    list-style-type: none
  }

  .lst-kix_list_16-4 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_4-1 {
    list-style-type: none
  }

  .lst-kix_list_16-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_16-5 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_4-4 {
    list-style-type: none
  }

  ul.lst-kix_list_4-5 {
    list-style-type: none
  }

  ul.lst-kix_list_4-2 {
    list-style-type: none
  }

  ul.lst-kix_list_4-3 {
    list-style-type: none
  }

  ul.lst-kix_list_12-6 {
    list-style-type: none
  }

  ul.lst-kix_list_12-5 {
    list-style-type: none
  }

  .lst-kix_list_17-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_12-4 {
    list-style-type: none
  }

  ul.lst-kix_list_12-3 {
    list-style-type: none
  }

  ul.lst-kix_list_12-2 {
    list-style-type: none
  }

  ul.lst-kix_list_12-1 {
    list-style-type: none
  }

  .lst-kix_list_17-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_12-0 {
    list-style-type: none
  }

  .lst-kix_list_17-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_17-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_17-4 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_12-8 {
    list-style-type: none
  }

  ul.lst-kix_list_12-7 {
    list-style-type: none
  }

  .lst-kix_list_17-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_7-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_17-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_2-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_2-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_2-8 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_7-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_7-5 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_7-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_7-5 {
    list-style-type: none
  }

  .lst-kix_list_10-0 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_7-6 {
    list-style-type: none
  }

  .lst-kix_list_18-5 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_7-3 {
    list-style-type: none
  }

  ul.lst-kix_list_7-4 {
    list-style-type: none
  }

  .lst-kix_list_13-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_13-8 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_18-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_18-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_7-7 {
    list-style-type: none
  }

  ul.lst-kix_list_7-8 {
    list-style-type: none
  }

  ul.lst-kix_list_7-1 {
    list-style-type: none
  }

  ul.lst-kix_list_7-2 {
    list-style-type: none
  }

  ul.lst-kix_list_7-0 {
    list-style-type: none
  }

  .lst-kix_list_7-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_15-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_15-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_10-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_10-8 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_4-0 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_15-3 {
    list-style-type: none
  }

  ul.lst-kix_list_15-2 {
    list-style-type: none
  }

  .lst-kix_list_15-0 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_15-1 {
    list-style-type: none
  }

  .lst-kix_list_15-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_15-0 {
    list-style-type: none
  }

  .lst-kix_list_10-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_4-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_20-7 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_15-8 {
    list-style-type: none
  }

  .lst-kix_list_4-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_4-6 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_15-7 {
    list-style-type: none
  }

  ul.lst-kix_list_15-6 {
    list-style-type: none
  }

  .lst-kix_list_9-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_15-5 {
    list-style-type: none
  }

  ul.lst-kix_list_15-4 {
    list-style-type: none
  }

  .lst-kix_list_15-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_10-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_9-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_9-7 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_11-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_12-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_9-5 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_6-6 {
    list-style-type: none
  }

  ul.lst-kix_list_6-7 {
    list-style-type: none
  }

  .lst-kix_list_20-5 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_6-4 {
    list-style-type: none
  }

  ul.lst-kix_list_6-5 {
    list-style-type: none
  }

  ul.lst-kix_list_6-8 {
    list-style-type: none
  }

  .lst-kix_list_12-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_11-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_1-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_20-1 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_20-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_6-2 {
    list-style-type: none
  }

  .lst-kix_list_11-8 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_6-3 {
    list-style-type: none
  }

  .lst-kix_list_1-2 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_6-0 {
    list-style-type: none
  }

  .lst-kix_list_12-0 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_6-1 {
    list-style-type: none
  }

  .lst-kix_list_1-4 > li:before {
    content: "\0025cb  "
  }

  .lst-kix_list_13-0 > li:before {
    content: "\0025a0  "
  }

  ul.lst-kix_list_14-4 {
    list-style-type: none
  }

  ul.lst-kix_list_14-3 {
    list-style-type: none
  }

  ul.lst-kix_list_14-2 {
    list-style-type: none
  }

  .lst-kix_list_13-4 > li:before {
    content: "\0025cb  "
  }

  ul.lst-kix_list_14-1 {
    list-style-type: none
  }

  ul.lst-kix_list_14-0 {
    list-style-type: none
  }

  .lst-kix_list_1-6 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_14-8 {
    list-style-type: none
  }

  ul.lst-kix_list_14-7 {
    list-style-type: none
  }

  .lst-kix_list_2-0 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_12-6 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_14-6 {
    list-style-type: none
  }

  ul.lst-kix_list_14-5 {
    list-style-type: none
  }

  .lst-kix_list_1-8 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_2-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_13-2 > li:before {
    content: "\0025a0  "
  }

  .lst-kix_list_12-8 > li:before {
    content: "\0025a0  "
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c14 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 72.4pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c18 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 156.4pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c8 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 239.2pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c6 {
    padding-top: 11pt;
    padding-bottom: 11pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
  }

  .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: "Arial";
    font-style: normal
  }

  .c33 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c24 {
    padding-top: 11pt;
    padding-bottom: 11pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c20 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c22 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c7 {
    padding-top: 11pt;
    padding-bottom: 11pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c21 {
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c34 {
    margin-top: 5%;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c5 {
    color: #595959;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
  }

  .c3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c0 {
    text-decoration-skip-ink: none;
    font-size: 10pt;
    -webkit-text-decoration-skip: none;
    color: #595959;
    text-decoration: underline
  }

  .c12 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: italic
  }

  .c25 {
    font-weight: 400;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
  }

  .c9 {
    margin-left: -5pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
  }

  .c29 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none
  }

  .c36 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
  }

  .c31 {
    color: #3030f1;
    text-decoration: none
  }

  .c10 {
    margin-left: 36pt;
    padding-left: 0pt
  }

  .c16 {
    padding: 0;
    margin: 0
  }

  .c35 {
    color: #7f7f7f;
    text-decoration: none
  }

  .c15 {
    color: inherit;
    text-decoration: inherit
  }

  .c11 {
    color: #595959
  }

  .c32 {
    color: #1155cc
  }

  .c19 {
    color: #000000
  }

  .c26 {
    height: 51.2pt
  }

  .c30 {
    font-weight: 700
  }

  .c27 {
    height: 25pt
  }

  .c28 {
    height: 49.8pt
  }

  .c4 {
    font-size: 10pt
  }

  .c13 {
    height: 11pt
  }

  .c23 {
    margin-left: 15pt
  }

  .c17 {
    font-style: italic
  }

  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }
}
