@import "./common/styles/custom.scss";

// .MuiAppBar-colorPrimary {
//   color: white !important;
// }

.backdrop{
  color: #fff;
  z-index: 1201 !important;
}
.app{
  flex-direction: column;
}

.bhooky-content{
  width: 80% !important;
  margin: auto;
}

@media only screen and (max-width: 1000px) {
   .bhooky-content{
  width: 100% !important;
  margin: auto;
}
}

.cursor{
  cursor: pointer;
}