.search-container {
    text-align: center;

    .user-location-input {
        width: 90%;
    }

    .user-location__dropdown {
        transition: .2s linear;
        max-height: 200px;
        overflow: hidden;
        padding: 0 1rem;
    }
}