.BecomeaPartner {}

.grid-timeline{
    font-variant-caps: small-caps;
    font-size: 20px;
}
.css-yk16xz-control{
    min-height: 57px !important;
}
.css-1pahdxg-control:hover{
    border-color: black !important;
}
.css-1pahdxg-control{
    min-height: 57px !important;
    box-shadow: 0 0 0 1px black !important;
    border-color: black !important;
}
.css-26l3qy-menu{
    z-index: 999 !important;
    margin-top: 0px !important;
}
.css-2b097c-container{
    margin-top: 10px;
}

.btn-primary-full{
    width: 100%;
    padding: 1% 0;
    margin: 5% 0;
    background: #3b5998;
    color: white;
}