.signup-wrapper {
    display: flex;
    justify-content: space-between;
}
.signup-form {
    padding: 1rem 2rem;
    height: 560px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .link-to-login {
        transition: .3s linear;
        font-size: .875rem;
    }
    .terms-and-privacy-statement {
        font-size: .925rem;
    }
    .pale-text {
        color: #9b9b9b;
    }
}
.signup-illustration {
    padding: 1rem 2rem;
    width: 100%;
    height: 500px;
    border-radius: 50%;
    background: url('../../assets/welcome_signup.svg') right no-repeat;
}
.btn-signup {
    width: 240px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .signup-wrapper {
        display: flex;
        flex-wrap: wrap-reverse;
    }
    
    .signup-form {
        padding: 1rem;
        margin-bottom: 4rem;
        div {
            padding: 0 !important;
        }
    }
}