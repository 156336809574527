.restaurant-details-banner{
    position: relative;
}
.overlay{
    background:black;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    position: absolute;
}
.res-name{
    margin-left: -2px !important;
    font-family: 'BhookyFont-Semibold' !important;
}
.item-dialog{
    .MuiDialogTitle-root{
        flex: 9;
    }
    .close{
        flex:1
    }
}

.product-description {
    padding: 1rem;
    margin: 1rem 0;
    text-transform: capitalize;
}

.product-image {
    max-height: 200px;
    width: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .products-wrapper {
        justify-content: center;
    }
}

