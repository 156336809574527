._3xMk0 {
  border: 1px solid #d4d5d9;
  padding: 30px;
  margin: 24px 0 37px
}

.g28rk {
  padding-bottom: 20px;
  background-image: linear-gradient(90deg, #a9abb2 25%, hsla(0, 0%, 100%, 0) 0);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
  display: -ms-flexbox;
  display: flex
}

.g28rk:hover {
  cursor: pointer
}

._359Fc {
  background: rgba(40, 44, 63, .05);
  width: 150px;
  height: 100px;
  cursor: pointer
}

._359Fc img {
  height: 100px;
  max-width: 100%
}


._2tuBw {
  opacity: 1 !important
}

._12_oN {
  opacity: 0;
  transition: opacity .3s ease-out
}

._2XWVq {
  -ms-flex: 1;
  flex: 1;
  position: relative;
  //padding-left: 18px
}
.green-color{
  color: green;
}
.red-color{
  color: #fc8019
}
.add-top-space{
  padding-top: 10px;
}

._3h4gz {
  font-size: 17px;
  font-weight: 500;
  color: #3d4152;
  display: inline-block;
  cursor: pointer
}

._3h4gz:hover {
  color: #fc8019
}

._2haEe {
  font-size: 13px;
  color: #686b78;
  text-transform: capitalize;
  margin-top: 3px;
  word-wrap: anywhere;
}


._2uT6l {
  margin-top: 6px;
  color: #7e808c;
  font-size: 12px
}

._1ziWV {
  margin-top: 14px;
  color: #008080;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: -.3px;
  display: inline-block
}

._1ziWV:hover {
  color: #282c3f
}

@media screen  and (min-width: 1000px){
  ._2fkm7 {
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 18px;
    font-size: 14px;
    color: #686b78
  }
  .font-size-7{
    font-size: 12px;
  }
}


.hDcdF {
  color: #fa4a5b;
  padding: 5px;
  margin-left: 5px;
  font-weight: 600
}

.icon-alert:before {
  content: "\E956"
}

._3SKK0 {
  padding-top: 20px;
  position: relative
}

.nRCg_ {
  font-size: 15px;
  padding-right: 240px;
  color: #282c3f;
  line-height: 20px
}


._2a27y {
  display: -ms-flexbox;
  display: flex
}

._3PUy8 {
  width: 120px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-right: 20px;
  line-height: 40px;
  height: 40px;
  color: #fc8019;
  border: 1px solid #008080;
  padding: 0 15px;
  margin-top: 20px;
  position: relative
}

._3PUy8:hover {
  position: relative;
  box-shadow: 0 2px 8px #d4d5d9
}

._3PUy8:hover:after {
  content: "";
  bottom: -1px;
  left: 0;
  position: absolute;
  width: 100%;
  height: 1px
}

._3vi_e {
  //position: fixed;
  //top: 0;
  height: 100%;
  //overflow-y: scroll;
  background: #fff;
  transition: transform .3s ease-out;
  z-index: 10001;
  transform: translateZ(0)
}

._23DHc {
  float: right;
  position: absolute;
  top: 22px;
  right: 0;
  color: #535665;
  font-size: 14px
}



//._12S7_ {
//  position: relative
//}

._1OTcX,
._2MUP_,
._3btQx {
  position: absolute;
  left: 0;
  right: 0;
  visibility: hidden;
  height: 15px;
  background: #ff0
}

._3btQx {
  top: 0
}

._1L8WG {
  padding: 32px 0 20px;
  margin-bottom: 30px
}
.SSFcO {
  float: left;
  color: #3d4152;
  cursor: pointer
}
.icon-close:before {
  content: "\E927"
}
._2Joay {
  margin-left: 30px;
  font-size: 1.3rem;
  font-weight: 700;
  color: #282c3f;
  line-height: 1.2rem
}

._2d5vz {
  margin-bottom: 18px;
  margin-top: -15px
}

._3AnQo {
  margin-bottom: 22px;
  display: -ms-flexbox;
  display: flex;
  position: relative
}
._3OR7J {
  font-size: 25px;
  padding-right: 20px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center
}

.icon-location:before {
  content: "\E911"
}

.icon-location-crosshair:before {
  content: "\E914"
}


._3SB3Y {
  font-size: 17px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 325px
}
._2d1Bv {
  font-size: 12px;
  color: #7e808c
}

._2tXGx {
  border-bottom: 1px solid #e9e9eb;
  left: 10%;
  width: 90%;
  margin-top: -5px;
  padding-top: 3px
}

._1QB2L,
._2tXGx {
  position: relative
}


._1QB2L {
  padding-bottom: 20px;
  display: -ms-flexbox;
  display: flex
}

._2m9jl {
  font-size: 15px;
  padding-right: 22px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: #7e808c
}

.bhsQP {
  color: #fa4a5b
}

.icon-alert:before {
  content: "\E956"
}

.i5V29 {
  font-size: 12px;
  color: #7e808c;
  line-height: 15px
}

._2e0BB {
  margin-left: 5px;
  font-weight: 400;
  color: #3d4152
}

._2e0BB:before {
  content: "\20B9";
  padding-right: 2px
}
._9xEZF {
  padding-top: 28px;
  padding-bottom: 3px;
  border-top: 1px solid #000;
  background-image: linear-gradient(90deg, #a9abb2 25%, hsla(0, 0%, 100%, 0) 0);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x
}
._1_7YY {
  color: #7e808c;
  padding-bottom: 8px;
  font-size: 12px
}
._3-AoR {
  position: relative;
  margin-bottom: 16px;
  font-size: 1rem;
  color: #282c3f
}
._2RM05 {
  position: absolute;
  width: 3px;
  border-radius: 5px;
  height: 100%;
  left: -14px
}

.VgVhA {
  color: inherit;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between
}

._3Z4QO {
  width: 60%
}

.ER1bp {
  font-weight: 500;
  font-size: 13px;
  color: #3d4152
}

._3dzo5,
.ER1bp {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

._3dzo5 {
  font-size: 11px;
  color: #7e808c;
  margin-top: 2px;
  font-weight: 300
}

._3yTW- {
  -ms-flex-pack: distribute;
  justify-content: space-around
}

._3svQP,
._3yTW- {
  display: -ms-flexbox;
  display: flex
}

._2cQvo {
  color: #686b78;
  font-weight: 300;
  font-size: 13px
}

._2cQvo:before {
  content: "\20B9";
  margin-right: .1rem
}

._29fhq {
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px
}
.icon-foodSymbol:before {
  content: "\E944"
}
.lTzAm {
  font-size: 10px;
  position: relative;
  top: 2px;
  line-height: 1 !important
}

._1tih2 {
  margin-left: 10px;
  margin-right: 5px;
  -ms-flex-positive: 1;
  flex-grow: 1
}

._1i0MM {
  font-weight: 500;
  overflow: hidden;
  font-size: 12px;
  color: #3d4152
}

._15Yxr {
  padding-top: 18px;
  padding-bottom: 11px
}
._3l9s2 {
  margin-bottom: 10px;
  color: #7e808c;
  display: -ms-flexbox;
  display: flex
}

._3l_-K {
  font-size: 13px;
  -ms-flex-positive: 1;
  flex-grow: 1
}
.UCNHQ {
  color: #282c3f;
  font-weight: 400
}
._3IQOi {
  font-size: 13px
}


._3RbEL,
._3Za6N,
._255pY {
  position: -webkit-sticky;
  position: sticky;
  background: #fff;
  z-index: 1
}

._3RbEL {
  top: 0;
  box-shadow: 0 2px 4px 0 #e9e9eb
}

._3Za6N {
  bottom: 0;
  box-shadow: 0 -2px 4px 0 #e9e9eb
}
._2FcHT {
  display: block
}

._2FcHT>._2ggYC:first-child:not(:last-child) {
  background-image: linear-gradient(90deg, #a9abb2 25%, hsla(0, 0%, 100%, 0) 0);
  background-position: top;
  background-size: 4px 1px;
  background-repeat: repeat-x
}

._2FcHT>._2ggYC:not(:first-child):not(:last-child) {
  border-top: 1px solid rgba(0, 0, 0, .1)
}

._2FcHT>._2ggYC:last-child {
  border-top: 1px solid
}
._8b3yo {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #686b78
}

._2m6Fe {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #282c3f
}

._2ggYC,
._2m6Fe {
  display: -ms-flexbox;
  display: flex
}

._1OfuV {
  margin-right: 40px;
  text-transform: uppercase
}

.rupee:before {
  content: "\20B9";
  margin-right: .1rem
}
._1OTcX,
._2MUP_,
._3btQx {
  position: absolute;
  left: 0;
  right: 0;
  visibility: hidden;
  height: 15px;
  background: #ff0
}

._3btQx {
  top: 0
}

._2MUP_ {
  bottom: 0
}

.orders-table{
  table-layout: fixed;
  width: 25em;
  th, td{
    padding: 2%;
  }
}

.aboutRating{
  width: 100%;
  resize: none;
  border: 1px solid #dddddd;
}

.rate-us-div{
  padding: 3% 0;
}

@media screen  and (max-width: 1000px){
  .font-size-10{
    font-size: 10px;
    padding-left: 2%;
  }
  .break-address{
    word-break: break-all;
  }
  .font-size-7{
    font-size: 7px;
  }
  .orders-table{
    font-size: 10px;
    table-layout: fixed;
    width: 100%;
    th, td{
      font-size: 10px;
      padding: 2%;
    }
  }
}


.side-panel  {
  width: 0;
  position: fixed;
  z-index: 1;
  height: 100vh;
  top: 0;
  right: 0;
  border-left: 1px solid #dddddd;
  box-shadow: 1vh 1vh 1vh 1vh black;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
