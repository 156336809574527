#root {
	overflow: hidden;
}

.fixed {
	overflow: hidden;
}

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
	background: #ffffff;
	box-shadow: rgba(122, 72, 72, 0.2) 0px 8px 6px -6px;

	a {
		text-decoration: none;
		color: #191818;
		transition: 0.2s linear;
		&:hover {
			color: #008382;
		}
	}

	&__brand {
		flex-basis: 20%;
		&:hover {
			img {
				transform: scale(1.1);
			}
		}

		&__logo {
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: auto;
				height: 45px;
				transition: 0.25s linear;
				padding-right: 0.5rem;
			}
			.brand-name {
				margin-bottom: 0;
				font-size: 1.5rem;
				color: #008382;
			}
		}
	}

	.navbar_content_wrapper {
		display: flex;
		flex-basis: 80%
	}

	.user-location {
		&__input {
			border-radius: 6px;
		}
		&__dropdown {
			height: 200px;
			overflow: hidden;
			z-index: 10000;
		}

	}

	&__body {
		margin-left: auto;
		&__toggler {
			padding: 12px;
			border: none;
			outline: none;
			background: transparent;
			&.selected {
				.bar {
					&__1 {
						transform: rotate(-45deg);
					}

					&__3 {
						transform: rotate(45deg);
					}
				}
			}

			.sr-only {
				position: absolute;
				width: 1px;
				height: 1px;
				margin: -1px;
				padding: 0;
				overflow: hidden;
				clip: rect(0, 0, 0, 0);
				border: 0;
			}

			.bar {
				display: block;
				width: 22px;
				height: 3px;
				background: #191818;
				transform-origin: 18px;
				transition: 0.35s ease;
				border-radius: 6px;

				&__2 {
					opacity: 0;
					margin: 2px 0;
				}
			}
		}

		&__links {
			ul {
				display: flex;
				justify-content: space-between;
				align-items: center;
				list-style: none;
				margin-bottom: 0;

				li {
					padding: 0.5rem 1rem;

					a {
						&.selected {
							color: #008382;
						}
					}

					&.user-profile {
						.profile {
							&__wrapper {

								button {
									background: transparent;
									outline: none;
									border: none;

									img {
										width: 30px;
										height: auto;
									}

									svg {
										margin-left: 0.6rem;
										width: 15px;
										height: 15px;
										transition: 0.4s ease;

										&.arrow-selected {
											transform: rotate(-180deg);
										}
									}
								}
							}
							&__links {
								margin-top: .5rem;
								background-color: #fff;
								z-index: 1000;
								box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
								border-radius: 6px;
								overflow: hidden;
								transition: none;
								&.selected {
									border: 1px solid #bdbdbd;
								}
								ul {
									li {
										padding: .5rem;
										padding-left: 1rem;
										padding-right: 1rem;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 899px) {
	.navbar {
		position: relative;
		flex-wrap: nowrap;
		padding: .8rem .6rem;

		&__brand {
			&__logo {
				img {
					max-height: 40px;
				}
				.brand-name {
					font-size: 1.6rem;
				}
			}
		}

		.navbar_content_wrapper {
			flex-wrap: wrap;
		}	

		&__body {

			&__toggler {
				position: relative;
			}


			&__links {
				position: fixed;
				z-index: 1000;
				width: 100%;
				height: 100%;
				right: 0;
				padding: 0 1rem;
				background: #ffffff;
				transform: translateX(100%);
				transition: 0.35s linear;

				&.show {
					z-index: 1000;
					transform: translateX(0);
				}

				ul {
					margin: 0 5%;
					flex-direction: column;
					li {
						width: 100%;
						padding: 1rem 0;
						border-bottom: 1px solid #dddcdc;

						a {
							display: block;
							font-size: 1.05rem;
						}
					}
				}
			}
		}

		.user-profile {
			.profile__links {
				max-width: 240px;
			}
		}
		
		.user-location {
			&__wrapper {
				display: none;
				width: 100%;
				margin-top: 1rem;
			}
			&__input {
				width: 100%;
			}
			&__dropdown {
				background-color: white;
				border-radius: 20px;
				margin-top: 1rem;
				box-shadow: 0 2px 2px #858585;
			}
		}
	}
}

@media (min-width: 900px) {
	.navbar {
		.user-location {
			&__wrapper {
				margin-left: auto;
				& + .navbar__body {
					margin-left: 2rem;
				}
			}

			&__dropdown {
				position: absolute;

				&__loading {
					display: block;
				}
			}
		}

		&__body {
			display: flex;
			align-items: center;
			
			&__toggler {
				display: none;
			}

			&__links {
				li {
					&.user-profile {
						position: relative;
						.profile {

							&__links {
								margin-top: 0.5rem;
								width: max-content;
								position: absolute;
								left: 0;
								height: 0;
								overflow: hidden;
								transition: 0.25s linear;

								&.selected {
									height: auto;
								}

								ul {
									flex-direction: column;
									box-shadow: 0 14px 28px
											rgba(0, 0, 0, 0.25),
										0 10px 10px rgba(0, 0, 0, 0.22);
									li {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
