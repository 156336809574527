.item-price::before{
    content: "$";
}
.item-title, .restaurant-card:hover{
    cursor: pointer;
}

.restaurant-card {
    margin: 1rem 2rem;
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
    .restaurant-card {
        margin: 2rem 1rem;
    }
}