.item-card {
    box-shadow: none !important;
}
.item-name{
    flex: 5;
    text-transform: capitalize;
}
.item-btns{
    flex: 3;
}
.item-price{
    flex: 2;
}
.item-price::before{
    content: "$";
}
