.login-form {
    box-shadow: 0px 4px 8px rgb(226, 226, 226);
    border-radius: 6px;
    padding: 1.8rem 2.4rem;
    .heading {
        text-align: center;
        h5 {
            margin: 1rem 0;
        }
    }
    .form-fields {
        max-width: 400px;
        margin: 1rem auto;
        input {
            padding: 1.4rem;
        }
    }
    .forgot-password-wrapper {
        text-align: center;
        a {
            font-size: .875rem;
        }
    }
}
.btn-login {
    background-color: #008382;
    color: white;
    width: 100%;
    transition: .3s linear;
    &:hover {
        color: white;
        background-color: #027272;
    }
}