.reviews{
        display: grid !important;
         grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
        padding: 30px 0;
        .cards {
              grid-template-rows: 10% 100px auto;
            display: grid;
            grid-template-columns:repeat(auto-fit, minmax(20px, 1fr));
            grid-gap: 30px;
          }
          
        .MuiCard-root {
            grid-column-end: span 5;
            display: flex;
            flex-direction: column;
            transition: all 0.3s ease 0s;
          }
}