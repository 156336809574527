@media screen and (min-width: 1000px) {

  .stripe {
    margin: 5% 25%;

    label {
      color: #6b7c93;
      font-weight: 300;
      letter-spacing: 0.025em;
    }

    button {
      white-space: nowrap;
      border: 0;
      outline: 0;
      display: block;
      width: 500px;
      height: 40px;
      line-height: 40px;
      padding: 0 14px;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      color: #fff;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      background-color: #6772e5;
      text-decoration: none;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
      margin-top: 10px;
    }

    button:hover {
      color: #fff;
      cursor: pointer;
      background-color: #7795f8;
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }

    input,
    .StripeElement {
      display: block;
      margin: 10px 0 20px 0;
      width: 700px;
      padding: 10px 14px;
      font-size: 1em;
      font-family: "Source Code Pro", monospace;
      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
      border: 1px solid black;
      outline: 0;
      border-radius: 4px;
      background: white;
    }

    input::placeholder {
      color: #aab7c4;
    }

    input:focus,
    .StripeElement--focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }

    .StripeElement.IdealBankElement,
    .StripeElement.FpxBankElement,
    .StripeElement.PaymentRequestButton {
      padding: 0;
    }
  }

}

@media screen and (min-width: 600px) and (max-width: 100px){
.stripe{

  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: block;
    width: 400px;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
  }

  button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    width: 400px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 1px solid black;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
}
}


@media screen and (max-width: 600px) {
  .stripe{
    margin: 5%;
    label {
      color: #6b7c93;
      font-weight: 300;
      letter-spacing: 0.025em;
    }

    button {
      white-space: nowrap;
      border: 0;
      outline: 0;
      display: block;
      width: 300px;
      height: 40px;
      line-height: 40px;
      padding: 0 14px;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      color: #fff;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      background-color: #6772e5;
      text-decoration: none;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
      margin-top: 10px;
    }

    button:hover {
      color: #fff;
      cursor: pointer;
      background-color: #7795f8;
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }

    input,
    .StripeElement {
      display: block;
      margin: 10px 0 20px 0;
      width: 300px;
      padding: 10px 14px;
      font-size: 1em;
      font-family: "Source Code Pro", monospace;
      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
      border: 1px solid black;
      outline: 0;
      border-radius: 4px;
      background: white;
    }

    input::placeholder {
      color: #aab7c4;
    }

    input:focus,
    .StripeElement--focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }
  }
}