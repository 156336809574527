.bg-image-about-us{
    background: url('../../assets/main-home.jpg') no-repeat center;

    bottom: 0;
    top: 81px;
    width: 100%;
}
.h-100vh{
        height: 100vh !important;
}
.op-5{
    opacity: 0.5 !important;
}

@media screen and (max-width: 1000px) {
    .about-us-overlay{
        background-color: black;
        opacity: 0.1;
        position: absolute;
        height: 100%;
        width: 100%;
    }
    .qr-code{
        text-align: center;
        width: 50%;
    }
}

@media screen and (min-width: 1000px){
    .about-us-overlay{
        background-color: black;
        opacity: 0.1;
        position: absolute;
        height: 100%;
        width: 80%;
    }
    .qr-code{
        text-align: center;
        width: 25%;
    }
}

.btn-download-android{
    font-variant-caps: small-caps;
    //opacity: 0.8;
    //background-color: green;
    border: none;
    color: white;
    padding: 12px 30px;
    font-weight: bold;
}

.margin-top-10{
    margin-top: 10%;
}