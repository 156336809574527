@media screen and (min-width: 1000px){
    .div-profile-picture{
        text-align: center;
        width: 10%;
        margin: 2% 45%;
    }
    .div-profile-picture:hover .edit-profile-picture{
        display: block;
    }
    .div-profile-picture p{
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
    }
    .profile-picture{
        width: 100%;
        display: block;
        margin: 10% 0;
        border-radius: 50%;
        border: 1px solid black;
    }
    .div-user-details-form{
        font-size: 18px;
        font-family: "Courier New", fantasy;
        text-align: left;
        margin: 0 40%;
    }
    .div-input{
        border-bottom: 2px solid #ddd;
        margin-top: 5%;
        margin-bottom: 3%;
    }
    .div-user-details-form label{
        display: block;
        font-weight: bold;
    }
    .real-input{
        width: 70%;
        padding: 5% 5% 5% 0;
        margin-right: 2%;
        border: none;
        font-size: 18px;
    }
    .change-input{
        width: 100%;
        margin-right: 0;
        padding: 5% 4% 5% 0;
        border: 1px solid black;
        font-size: 18px;
        margin-bottom: 2%;
    }

    .change-button{
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: #2e9f9d;
        font-size: 18px;
        font-weight: bold;
        float: right;
        padding: 5% 5% 5% 0;
    }
    .edit-profile-picture{
        border: none;
        background-color: #2e9f9d;
        cursor: pointer;
        color: white;
        /*font-size: 48px;*/
        font-weight: bold;
        text-align: center;
        display: none;
    }

    .verify-button{
        width: 100%;
        border: none;
        cursor: pointer;
        background-color: #2e9f9d;
        color: white;
        font-size: 18px;
        font-weight: bold;
        padding: 2%;
    }
    .flex{
        display: flex;
    }
    .payments{
        color: #2e9f9d;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        background-color: transparent;
        padding: 5% 0;
        display: block;
    }
    .payments:hover{
        text-transform: none;
    }
    .payments span{
        padding: 0 5%;
        font-size: 18px;
        font-weight: 700;
        font-family: "Courier New", fantasy;
    }
    .cards-details{
        width: 100%;
        table-layout: fixed;
        color: black;
        font-size: 16px;
        font-weight: normal;
    }
    .cards-details th{
        padding: 5% 0;
    }
    .cards-details td{
        text-align: center;
        padding: 5% 30%;
        color: maroon;
    }
}

@media screen and (max-width: 1000px){
    .div-profile-picture{
        text-align: center;
        border: 1px solid black;
    }
    .div-profile-picture .profile-picture{
        width: 30%;
        margin: 2% 35%;
    }
    .div-profile-picture .user-name{
        font-size: 24px;
        font-weight: bold;
    }
    .div-user-details-form{
        font-size: 18px;
        font-family: "Courier New", fantasy;
        text-align: left;
        margin: 0 10%;
    }
    .edit-profile-picture{
        border: none;
        background-color: #2e9f9d;
        cursor: pointer;
        color: white;
        margin: 5% 35%;
        font-size: 0.8em;
        font-weight: bold;
        text-align: center;
    }
    .div-input{
        border-bottom: 2px solid #ddd;
        margin-top: 5%;
        margin-bottom: 3%;

    }
    .div-user-details-form label{
        display: block;
        font-weight: bold;
    }
    .real-input{
        width: 70%;
        padding: 5% 5% 5% 0;
        margin-right: 2%;
        border: none;
        font-size: 14px;
        background-color: transparent;
    }
    .change-input{
        width: 100%;
        margin-right: 0;
        padding: 5% 4% 5% 0;
        border: 1px solid black;
        font-size: 14px;
        margin-bottom: 2%;
    }

    .change-button{
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: #2e9f9d;
        font-size: 14px;
        font-weight: bold;
        float: right;
        padding: 5% 5% 5% 0;

    }
    .verify-button{
        width: 100%;
        border: none;
        cursor: pointer;
        background-color: #2e9f9d;
        color: white;
        font-size: 18px;
        font-weight: bold;
        padding: 2%;
    }
}
