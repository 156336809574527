.or-div{
    position: relative;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    
}
.or-hr{
    border-top-width: initial;
    border-right-width: initial;
    border-left-width: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    width: 100%;
    border-style: none none solid;
    border-image: initial;
    border-bottom: 1px solid rgb(232, 232, 232);
    padding: 0px;
}
.or-span{
    position: absolute;
    font-size: 1.8rem;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(105, 105, 105);
    padding: 0.1rem 0.6rem 0.5rem 0.5rem;
    background: rgb(255, 255, 255);
    font-size: 1rem;
}
.option-btns{
    font-size: 15px;
}