.CookiesAleart {
  .bottom {
    position: fixed;
    bottom: 0;
    background-color: black;
    color: white;
    text-align: center;
    padding: 1rem 4rem;
    width: 100%;
    font-size: 14px;
  }
  .cookie-policy-link{
    color: violet;
    cursor: pointer;
    margin: 4px;
    font-size: 14px;
  }
  .close-cookies-aleart{
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
    margin-left: .5rem;
  }
}

@media only screen and (max-width: 600px) {
  .CookiesAleart {
    .bottom {
      text-align: left;
      padding: 1rem 1rem;
    }
  }
}