// Banner Search
.landing-page-search {
  height: 550px;
  background: url('../../assets/main-screen.jpg') center no-repeat;
  background-size: cover;

  .search-heading {
    color: white;
    font-weight: 400;   
    font-size: 3rem;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 0.00735em;
  }
}

// Small Screens
@media only screen and (max-width: 600px) {
  .landing-page-search {
    .search-heading {
      font-size: 2.125rem;
    }
  }
} 

// Medium Devices
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .landing-page-search {
    .search-heading {
      font-size: 2.125rem;
    }
  }
} 

// Homepage content - sections
.homepage-content {
  width: 90%;
  margin: 0 auto;
  .brand-context-title {
    font-weight: bold;
  }
  .brand-context {
    padding: 0 1.5rem;
  }
  .brand-context-content {
    margin-top: 1rem;
  }
  .video-row {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .first-row {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .second-row {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

// Small Screens
@media only screen and (max-width: 600px) {
  .homepage-content {
    .brand-context-title {
      font-weight: bold;
      font-size: 1.5rem;
    }
    .video-row {
      flex-wrap: wrap;
      .video-wrapper {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        .brand-video {
          width: 100% !important;
          height: 100% !important;
        }
      }
      .brand-context {
        margin-top: 1rem;
        flex-basis: 100%;
        padding: 0;
      }
    }
  }
  .first-row, .second-row {
    flex-wrap: wrap;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .brand-context {
      margin-top: 1rem;
      padding: 0;
    }
  }
  .second-row {
    .brand-context {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
}

// Medium Devices
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .homepage-content {
    .brand-context-title {
      font-weight: bold;
      font-size: 2rem;
    }
    .video-row {
      flex-wrap: wrap;
      .video-wrapper {
        margin: 0 auto;
        overflow: hidden;
      }
      .brand-context {
        margin-top: 1rem;
        flex-basis: 100%;
        padding: 0;
      }
    }
    .first-row, .second-row {
      img {
        width: 320px;
        height: auto;
        object-fit: cover;
      }
    }
  }
} 