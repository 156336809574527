.landing-search-container {
  position: relative;
  width: 720px;

  .location-search-input {
    width: 100%;
    border-radius: 20px;
    font-size: 1rem;
    outline: none;
    padding: 1rem;
    margin-top: 2rem;
    &:focus{
      transition: 0.35s ease;
      color: black;
      &::-webkit-input-placeholder{
        transition: opacity 0.45s ease; 
          opacity: 0;
       }
      &::-moz-placeholder {
        transition: opacity 0.45s ease; 
          opacity: 0;
       }
      &:-ms-placeholder {
       transition: opacity 0.45s ease; 
         opacity: 0;
       }    
     }  
    }

    .btn-locate-me {
      position: absolute;
      right: 2%;
      top: 40px;
    }

    .autocomplete-dropdown-container {
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
      border-radius: 20px;
      background: white;
      max-width: 90%;
      position: absolute;
      left: 0;
      margin-top: 1rem;
    }
    .suggestion-item {
      margin: 1rem;
      padding: .6rem;
      text-align: left;
      background-color: #fff;
      cursor: pointer;
    }
    
    .suggestion-item--active {
      background-color: #fafafa;
      margin: 1rem;
      padding: .6rem;
      text-align: left;
      cursor: pointer;
    }
}

// Small Screens
@media only screen and (max-width: 600px) {
  .landing-search-container {
    width: 320px;
  }
} 

// Medium Devices
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .landing-search-container {
    width: 600px;
  }
} 