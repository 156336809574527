.dec-btn{
    padding-left: 3px !important;
}
.inc-btn{
    padding-right: 3px !important;
}
.dec-inc-btn{
    border: 1px solid black;
    border-radius: 30px;
}
.MuiIconButton-root{
    padding: 7px !important;
}
.item-count{
    font-size: 15px;
}
.summary-subheading{
    margin-left: 2px;
}
.summary-cart{
    width: auto !important;
    height: 100%;
    margin-top: 10%;
}
.summary-root{
    font-size: 12px;
    top: 10%;
    .column {
        float: left;
        width: 25%;
        padding: 10px 20px;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    /* Remove extra left and right margins, due to padding */
    .row {margin: 0 -5px;}

    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    /* Responsive columns */
    @media screen and (max-width: 600px) {
        .column {
            width: 100%;
            display: block;
            margin-bottom: 20px;
            background-color: white;
            //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        }
    }

    /* Style the counter cards */
    .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding: 16px;
        background-color: #ffffff;
    }
}

.cart-no-items{
    height: 250px;
    opacity: 0.2;
      img{
          width: 120px;
          height: 120px;
      }
}

.cart-secondary{
    min-height: 150px;
}
.summarycard-title{
    font-size: 16px;
    font-weight: bold;
    .MuiCardHeader-title{
    font-family: 'BhookyFont-Semibold' !important;
    }
}
.subtotal::before{
 content: "\20B9";
}
.summarycard-subtotal-title{
    .MuiCardHeader-title{
    font-family: 'BhookyFont-Medium' !important;
        font-size: 22px;
    }
    .summary-subheading{
        margin: unset;
    }
    .MuiTypography-caption{
        font-size: 10px;
    }
}
.clear-cart{
    align-self: flex-end !important;
    .MuiButton-label{
    font-size: 10px;
    }
}
.cart-items-root{
    .cart-items{
    overflow: auto;
        .MuiCardContent-root:last-child{
            padding-bottom:unset;
        }
        .add-or-remove .MuiIconButton-label .MuiSvgIcon-root{
            font-size: 0.8rem !important;
        }
    }
}


