.bg-image{
    background-image: url('../../assets/find-restaurant.jpg');
    padding: 20px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

@media screen and (min-width: 1000px) {
    .contact-form{
        width: 80%;
        background: white;
        margin: 30px 20%;
        border-radius: 10px;
        padding: 40px;
    }
    .contact-us{
        position: relative;
        left: 20%;
        right: 50%;
    }
}

@media screen and (max-width: 1000px) {
    .contact-form{
        background: white;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 10px;
        padding: 40px;
    }
}

.grid-overlay{
    background-color: #0c010187;
    // background-color: rgba(0,0,0,0.5);s
    z-index: 2;
    padding: 20px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
