.restaurant-card{
  display: flex;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 6px;
  overflow: hidden;

  .food-item-image {
    flex-basis: 25%;
  }

  .food-item-content {
    flex-basis: 75%;
  }

  .food-item-name {
    font-size: 1rem;
  }

  .MuiCardContent-root:last-child{
    padding: unset;
  }
}

// Mobile Screens
@media only screen and (max-width: 600px) {
  .restaurant-card {
    .food-item-name {
      max-width: 150px;
      font-size: 1rem;
    }
  }
}

// Tablet Screens
@media only screen and (max-width: 900px) {
}