.restaurant-main {

  .cards {
    display: grid;
    grid-template-columns:repeat(auto-fit, minmax(60px, 1fr));
    grid-gap: 35px;
  }

  .MuiCard-root {
    grid-column-end: span 4;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.3s ease 0s;
  }
}

.close-drawer {
  font-weight: 400 !important;
  font-size: 35px !important;
  width: 50px;
  height: 58px;
}

.restaurants-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.filter-title {
  font-weight: 600 !important;
  font-size: 15px !important;
}

.filters {
  width: 600px;
}

.j-items-start {
  justify-items: start;
}

.divider-filters {
  width: 95%;
}

.dialog-addtocart {
  background: black;
  width: 100%;
  bottom: 0px;
  margin: 0px;
  position: sticky;
  padding: 15px;
  right: 0px;
  left: 0px;

  .view-more .MuiButton-label {
    color: white;
    font-family: 'BhookyFont-Bold' !important;
  }

  .MuiButton-outlined {
    background: white;
  }

  .MuiButton-outlined:hover {
    background: white;
  }

  .add-or-remove {
    background: white;
  }

}

// Move Up Animation
@keyframes moveUp {
  0% {
    opacity: 0;
    bottom: -10px;
  }
  100% {
    opacity: 100;
    bottom: 0;
  }
}

// Cart Toggler
.cart-toggler {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, .5);
  background: #00808f;
  color: white;
  width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: fixed;
  right: 5%;
  bottom: 0;
  z-index: 100;
  animation: .4s moveUp;
  &.display-none {
    display: none;
  }

  h6 {
    margin: 0;
  }

  .cart-toggler-button {
    background: #019eaf;
    color: white;
    border-radius: 6px;
    outline: none;
    padding: .5rem 1rem;
  }
}

// Food Item Card
.search-result-details-link-more-from-merchant {
  font-size: 1rem;
  font-weight: 500;
  padding: .5rem;
  border-radius: .4rem;
  line-height: 1.65;
  letter-spacing: 0.01857em;
  max-width: 250px;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, .87);
}

// Mobile Screens
@media only screen and (max-width: 600px) {
  .cart-toggler {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    left: 0;
    width: 100%;
  }

  .filters {
    width: auto;
  }

  // Food Item Card
  .search-result-details-link-more-from-merchant {
    font-size: .875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    max-width: 150px;
    background-color: transparent;
    color: rgb(255, 255, 255);
    text-align: left; 
    text-decoration: underline;
  }

}

// Tablets
@media only screen and (max-width: 900px) {

  .cart-toggler {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    left: 0;
    width: 100%;
  }
}