// Override default variables before the import
$body-bg: #000;
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  box-sizing: border-box;
}
*:focus {
  outline: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input{
  font-family: 'BhookyFont-Medium' !important;
}

button {
  outline: none !important;
}
body {
 	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height:100%; padding:0; margin:0; position:relative;background: white !important;
	margin: auto;
   min-height: 100vh; 
  margin: 0;
}
body::after {
  content: '';
  display: block;
  height: 70px; /* Set same as footer's height */
}
body,
input,
button,h1,h2,h3,h4,h5,h6 {
  font-family: "BhookyFont-Medium" !important;
}

span{
  font-family: "BhookyFont-Regular" !important;
}

ul {
  list-style: none;
}
button {
  cursor: pointer;
}

.MuiContainer-maxWidthLg{
  max-width: none !important;
}
.MuiSnackbar-root{
  background: #02b3a3;
  border-radius: 30px;
  .MuiAlert-root {
    border-radius: 30px;
  }
  .MuiAlert-message{
    color: white !important;
  }
  .MuiAlert-action{
  color: white !important;
  }
}
.bhooky-thin{
  font-family: 'BhookyFont-Thin' !important;
}
.bhooky-light{
  font-family: 'BhookyFont-Light' !important;
}
.bhooky-regular{
  font-family: 'BhookyFont-Regular' !important;
}
.bhooky-semibold{
  font-family: 'BhookyFont-Semibold' !important;
}
.bhooky-medium{
  font-family: 'BhookyFont-Medium' !important;
}
.bhooky-bold{
  font-family: 'BhookyFont-Bold' !important;
}
.bhooky-extrabold{
  font-family: 'BhookyFont-ExtraBold' !important;
}
@font-face {
  font-family: 'BhookyFont-Regular';
  src: local('BhookyFont-Regular'), url(../fonts/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'BhookyFont-Bold';
  src: local('BhookyFont-Bold'), url(../fonts/Montserrat-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'BhookyFont-ExtraBold';
  src: local('BhookyFont-ExtraBold'), url(../fonts/Montserrat-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'BhookyFont-Medium';
  src: local('BhookyFont-Medium'), url(../fonts/Montserrat-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'BhookyFont-Semibold';
  src: local('BhookyFont-Semibold'), url(../fonts/Montserrat-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'BhookyFont-Light';
  src: local('BhookyFont-Light'), url(../fonts/Montserrat-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'BhookyFont-Thin';
  src: local('BhookyFont-Thin'), url(../fonts/Montserrat-Thin.ttf) format('truetype');
}

